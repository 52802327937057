/*------------- #THEME ITEM --------------*/


.crumina-theme-item {

  &:hover {

	.hover-btns-block {
	  opacity: 1;
	  visibility: visible;
	}

	.theme-item-thumb {

	  .overlay {
		opacity: 1;
	  }

	  img {
		transform: scale(1.1);
	  }
	}
  }
}

.theme-item-thumb {
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  .overlay {
	opacity: 0;
  }

  img {
	transition: transform 1s ease;
  }
}

.hover-btns-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;
}

.theme-item-content {
  text-align: center;
  padding: 30px;
}

/*------------- Responsive Mode --------------*/
