/*------------- #STYLES for RADIO buttons --------------*/


.radio label {
  cursor: pointer;
  padding-left: 40px;
  margin: 0;
  position: relative;
  color: inherit;
}
.radio label span {
  display: block;
  position: absolute;
  left: 10px;
  top: 0;
  transition-duration: 0.2s;
  margin-left: 0;
}
.radio label .circle {
  border: 2px solid var(--primary-accent-color);
  background-color: transparent;
  height: 18px;
  width: 18px;
  border-radius: 100%;
}
.radio label .check {
  height: 18px;
  width: 18px;
  border-radius: 100%;
  -webkit-transform: scale3d(0,0,0);
  -moz-transform: scale3d(0,0,0);
  -o-transform: scale3d(0,0,0);
  -ms-transform: scale3d(0,0,0);
  transform: scale3d(0,0,0);
}
.radio label .check:after {
  display: block;
  position: absolute;
  content: "";
  background-color: rgba(0,0,0, 0.87);
  left: -18px;
  top: -18px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  -webkit-transform: scale3d(1.5,1.5,1);
  -moz-transform: scale3d(1.5,1.5,1);
  -o-transform: scale3d(1.5,1.5,1);
  -ms-transform: scale3d(1.5,1.5,1);
  transform: scale3d(1.5,1.5,1);
}

.radio input[type=radio] {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  display: none;
}
.radio input[type=radio]:checked ~ .check, .radio input[type=radio]:checked ~ .circle {
  opacity: 1;
  border-color: var(--border-grey-color);
}
.radio input[type=radio]:checked ~ .check {
  background-color: var(--white-color);
}
.radio input[type=radio]:checked ~ .circle {
  border-color: var(--primary-accent-color);
  background-color: var(--primary-accent-color);
}
.radio input[type=radio]:checked:disabled ~ .check {
  background-color: var(--input-dark-bg);
  border-color: var(--input-dark-bg);
}

.radio input[type=radio]:checked:disabled ~ .circle {
  background-color: var(--dark-themes);
}

.radio input[type=radio]:checked ~ .check {
  -webkit-transform: scale3d(0.4, 0.4, 1);
  -moz-transform: scale3d(0.4, 0.4, 1);
  -o-transform: scale3d(0.4, 0.4, 1);
  -ms-transform: scale3d(0.4, 0.4, 1);
  transform: scale3d(0.4, 0.4, 1);
}

.radio input[type=radio][disabled] ~ .check {
  background-color: transparent;
}
.radio input[type=radio][disabled] ~ .circle {
  background-color: #1f2223;
  border-color: var(--input-dark-bg);
}

@keyframes rippleOn {
  0% {
	opacity: 0;
  }
  50% {
	opacity: 0.2;
  }
  100% {
	opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
	opacity: 0;
  }
  50% {
	opacity: 0.2;
  }
  100% {
	opacity: 0;
  }
}


/*------------- RADIO buttons Style2 --------------*/

.radio--style2 {

  &.radio label .circle {
	border: 1px solid var(--primary-accent-color);
	background-color: transparent;
  }

  &.radio input[type="radio"]:checked ~ .circle {
	border-color: var(--primary-accent-color);
	background-color: var(--primary-accent-color);
  }

  &.radio input[type="radio"]:checked:disabled ~ .check {
	background-color: var(--border-grey-color);
  }

  &.radio input[type="radio"]:checked:disabled ~ .circle {
	border-color: #bec8d5;
	background-color: transparent;
  }

  &.radio input[type="radio"][disabled] ~ .circle {
	border-color: #bec8d5;
	background-color: transparent;
  }
}


/*------------- RADIO buttons Style3 --------------*/

.radio--style3 {

  &.radio label .circle {
	border: 1px solid #bfc9d6;
	background-color: transparent;
  }

  &.radio input[type="radio"]:checked ~ .circle {
	border-color: var(--lime-themes);
	background-color: var(--lime-themes);
  }

  &.radio input[type="radio"]:checked:disabled ~ .check {
	background-color: var(--border-grey-color);
  }

  &.radio input[type="radio"]:checked:disabled ~ .circle {
	border-color: #bec8d5;
	background-color: var(--grey-themes);
  }

  &.radio input[type="radio"][disabled] ~ .circle {
	border-color: #bec8d5;
	background-color: var(--grey-themes);
  }
}