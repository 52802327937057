/*------------- #LISTS --------------*/


ul {
  a {
	display: block;
  }
}

ul:not([class]) {
  padding-left: 40px;
}

ul:not([class]),
ol:not([class]) {

  li {
	margin-bottom: 10px;

	a {
	  display: block;
	}
  }

  ul {
	padding: 0;
	padding-left: 40px;
	margin: 20px 0 15px;
	list-style: inherit;

	li {
	  font-size: 16px;
	  margin-bottom: 0;
	}
  }
}

ol {
  counter-reset: list1;
  margin: 15px 0;
  padding-left: 20px;

  li {
	list-style: none;
	transition: all .3s ease;
	position: relative;
	padding-left: 20px;
	margin-bottom: .35em;
	font-size: 16px;

	a {
	  display: inline-block;
	}
  }

  ul {
	li {
	  list-style: initial;
	  padding-left: 0;

	  &:before {
		display: none;
	  }
	}
  }

}

/* Init counter */

ol li:before {
  position: absolute;
  counter-increment: list1; /* Add "1" */
  content: counter(list1) "."; /* Show count */
  font-weight: 500;
  display: inline-block;
  left: 0;
}

ol ol {
  counter-reset: list2;
  margin: 10px 0;
  padding-left: 0;
}

ol ol li {
  padding-left: 40px;
}

ol ol li:before {
  counter-increment: list2;
  content: counter(list1) "." counter(list2) ". ";
}

ol ul ol {
  counter-reset: list1;

  li:before {
	counter-increment: list1; /* Add "1" */
	content: counter(list1) "."; /* Show count */
  }
}

ul ul,
ol ul,
ul ol {
  margin: 10px 0;
  padding-left: 0;
}

ul ol li::before {
  position: absolute;
  counter-increment: list1;
  content: counter(list1) ".";
  display: inline-block;
  font-size: 16px;
  left: 0;
}

ol ul ol li:before {
  counter-increment: list1; /* Add "1" */
  content: counter(list1) "."; /* Show count */
  display: inline-block;
  position: absolute;
  list-style: none;
  font-size: 16px;
  left: 0;
}

ol ol ol {
  counter-reset: list3;
}

ol ol ol li {
  padding-left: 55px;
}

ol ol ol li::before {
  counter-increment: list3;
  content: counter(list1) "." counter(list1) "." counter(list3) ".";
}

ul ol li {
  padding-left: 40px;
}

/*------------- Ordered List with border --------------*/

.ordered-list--with-border {
  li {
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px dashed var(--border-grey-color);

	&:last-child {
	  border-bottom: none;
	  margin-bottom: 0;
	  padding-bottom: 0;
	}

	li {
	  padding-bottom: 0;
	  margin-bottom: 0;
	  border-bottom: none;
	}
  }
}

/*------------- List Standard --------------*/

.list--standard {
  list-style: none;
  padding-left: 20px;

  li {
	list-style: none;
	font-weight: 500;
	margin-bottom: 10px;
	display: flex;
	align-items: center;

	&:last-child {
	  margin-bottom: 0;
	}

	.actual-info {
	  color: var(--light-grey);
	}

	.crumina-icon {
	  width: 10px;
	  height: 10px;
	  min-width: 10px;
	  min-height: 10px;
	  margin-right: 10px;
	}
  }
}

.list--icon-primary {
  .crumina-icon {
	fill: var(--primary-accent-color);
  }
}

.list--icon-red {
  .crumina-icon {
	fill: var(--red-themes);
  }
}

.list--icon-yellow {
  .crumina-icon {
	fill: var(--yellow-themes);
  }
}

.list--icon-orange {
  .crumina-icon {
	fill: var(--orange-themes);
  }
}

.list--icon-blue {
  .crumina-icon {
	fill: var(--blue-themes);
  }
}

.list--with-border {
  padding-left: 0;

  li {
	padding-left: 20px;
	padding-bottom: 10px;
	border-bottom: 1px dashed var(--border-grey-color);
  }

  .price {
	margin-left: auto;
	font-weight: 500;
  }
}

.websites-list {
  margin: 10px 0;
  padding-left: 0;

  li {
	border-bottom: 1px dashed var(--border-grey-color);
	padding: 15px 60px;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&:last-child {
	  border-bottom: none;
	}
  }

  .state {
	color: var(--lime-themes);
  }
}

/*------------- Responsive Mode --------------*/


@media (max-width: 640px) {

  ul li {
	font-size: 13px;
  }

  ol li {
	font-size: 13px;
	padding-left: 20px;
  }

  ol ol li {
	padding-left: 30px;
  }

  ol ol ol li {
	padding-left: 40px;
  }
}