/*------------- #CRUMINA-DROPZONE --------------*/


.crumina-dropzone {
  text-align: center;
  border-radius: 5px;
  border: 2px dashed #cad7e3;
  font-weight: 500;
  padding: 15px 20px;

  a {
	color: var(--primary-accent-color);
  }
}