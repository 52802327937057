/*------------- #TABLES --------------*/


table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #dfe6ec;
  font-size: 14px;
}

table thead {
  background-color: #f1f6fb;
  border-bottom: 1px solid #dfe6ec;
}

table * {
  transition: all .3s ease;
}

table > tbody > tr {
  border-bottom: 1px solid #dfe6ec;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1),inset 4px 0 0 0 rgba(100, 25, 255, 0.004);

    & > td:first-child {
      &:before {
        opacity: 1;
      }
    }
  }
}

table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  padding: 20px 30px;
  line-height: 1.42857143;
  vertical-align: middle;
}

table > tbody > tr > td:first-child {
  border-right: none;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 3px;
    height: 100%;
    background-color: var(--primary-accent-color);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all .3s ease;
  }
}

table > thead > tr > th {
  text-transform: uppercase;
  vertical-align: middle;
  color: var(--dark-themes);
}

table > tbody + tbody {
  border-top: 2px solid var(--border-grey-color);
}

table table {
  background-color: var(--white-color);
}

table col[class*="col-"] {
  position: static;
  display:table-column;
  float: none;
}

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display:table-cell;
  float: none;
}

table > thead > tr > td.active,
table > tbody > tr > td.active,
table > tfoot > tr > td.active,
table > thead > tr > th.active,
table > tbody > tr > th.active,
table > tfoot > tr > th.active,
table > thead > tr.active > td,
table > tbody > tr.active > td,
table > tfoot > tr.active > td,
table > thead > tr.active > th,
table > tbody > tr.active > th,
table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

table > thead > tr > td.success,
table > tbody > tr > td.success,
table > tfoot > tr > td.success,
table > thead > tr > th.success,
table > tbody > tr > th.success,
table > tfoot > tr > th.success,
table > thead > tr.success > td,
table > tbody > tr.success > td,
table > tfoot > tr.success > td,
table > thead > tr.success > th,
table > tbody > tr.success > th,
table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

table > thead > tr > td.info,
table > tbody > tr > td.info,
table > tfoot > tr > td.info,
table > thead > tr > th.info,
table > tbody > tr > th.info,
table > tfoot > tr > th.info,
table > thead > tr.info > td,
table > tbody > tr.info > td,
table > tfoot > tr.info > td,
table > thead > tr.info > th,
table > tbody > tr.info > th,
table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

table > thead > tr > td.warning,
table > tbody > tr > td.warning,
table > tfoot > tr > td.warning,
table > thead > tr > th.warning,
table > tbody > tr > th.warning,
table > tfoot > tr > th.warning,
table > thead > tr.warning > td,
table > tbody > tr.warning > td,
table > tfoot > tr.warning > td,
table > thead > tr.warning > th,
table > tbody > tr.warning > th,
table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

table > thead > tr > td.danger,
table > tbody > tr > td.danger,
table > tfoot > tr > td.danger,
table > thead > tr > th.danger,
table > tbody > tr > th.danger,
table > tfoot > tr > th.danger,
table > thead > tr.danger > td,
table > tbody > tr.danger > td,
table > tfoot > tr.danger > td,
table > thead > tr.danger > th,
table > tbody > tr.danger > th,
table > tfoot > tr.danger > th {
  background-color: #f2dede;
}


/*------------- #TABLES Style1--------------*/

.table--style1 {
  border: none;
  width: 100%;
  table-layout: fixed;

  .crumina-icon {

    &.icon-check {
      width: 24px;
      height: 19px;
      fill: var(--lime-themes);
    }

    &.icon-minus {
      width: 24px;
      height: 3px;
      fill: var(--dark-themes);
    }
  }

  & > thead > tr > th,
  & > tbody > tr > th,
  & > tfoot > tr > th,
  & > thead > tr > td,
  & > tbody > tr > td,
  & > tfoot > tr > td {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    padding: 38px 30px;
    text-transform: initial;

    &:first-child {
      text-align: left;
      text-transform: uppercase;
      color: var(--dark-themes);
      font-weight: 700;
    }
  }

  & > tbody > tr > td {
    border-right: 1px solid #dfe6ec;
  }

  & > tbody > tr > td:first-child {
    border-right: 1px solid #dfe6ec;
  }

  & > tbody > tr > td:last-child {
    border-right: none;
  }

  & > tbody > tr > td:first-child::before {
    display: none;
  }

  .info-icon {
    top: -3px;
    position: relative;
  }

  thead {
    background-color: transparent;
    border-bottom: 2px solid #dfe6ec;
  }

  & > thead > tr > th {
    font-weight: 700;
    font-size: 24px;
  }

  & > tbody > tr:last-child {
    border-bottom: 1px solid #dfe6ec;
  }

  & > tbody > tr:hover {
    box-shadow: none;
  }
}


/*------------- #TABLES Style2--------------*/

.table--style2 {
  border: none;

  thead {
    background-color: transparent;
    border-bottom: none;
  }

  tbody {
    border: 1px solid #dfe6ec;
  }

  & > tbody > tr > td {
    font-size: 16px;
    font-weight: 600;
  }

  .sale {
    font-size: 14px;
    display: block;
    color: var(--light-grey);
    text-decoration: line-through;
    font-weight: 500;
  }

  & > tbody > tr > td:first-child {
    font-size: 18px;
    font-weight: 700;
  }

  .point {
    color: var(--light-grey);
  }

  & > tbody > tr > td:first-child::before {
    display: none;
  }

  & > thead > tr > th,
  & > tbody > tr > th,
  & > tfoot > tr > th,
  & > thead > tr > td,
  & > tbody > tr > td,
  & > tfoot > tr > td {
    padding: 10px 20px;
  }
}

/*------------- #TABLES Style3--------------*/

.table--style3 {
  border: none;
  background-color: var(--white-color);

  thead {
    background-color: transparent;
    border-bottom: none;
  }

  tbody {
    border: 1px solid #dfe6ec;
  }

  & > tbody > tr > td {
    font-size: 16px;
    font-weight: 600;
  }

  .sale {
    font-size: 14px;
    display: block;
    color: var(--light-grey);
    text-decoration: line-through;
    font-weight: 500;
  }

  & > tbody > tr > td:first-child {
    font-size: 18px;
    font-weight: 400;

    span {
      font-weight: 700;
    }
  }

  & > tbody > tr.already-taken > td:first-child {
    opacity: .5;
  }

  & > tbody > tr.already-taken > td:last-child {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--red-themes);
    text-align: center;
  }

  .point {
    color: var(--light-grey);
  }

  & > tbody > tr > td:first-child::before {
    background-color: var(--lime-themes);
  }

  & > tbody > tr > th,
  & > tfoot > tr > th,
  & > thead > tr > td,
  & > tbody > tr > td,
  & > tfoot > tr > td {
    padding: 10px 20px;
  }

  & > thead > tr > th {
    padding: 0;
  }
}


/*================= Responsive Mode ============*/

@media (max-width: 1024px) {
  .table--style1 > thead > tr > th,
  .table--style1 > tbody > tr > th,
  .table--style1 > tfoot > tr > th,
  .table--style1 > thead > tr > td,
  .table--style1 > tbody > tr > td,
  .table--style1 > tfoot > tr > td {
    padding: 10px 15px;
  }
}

@media (max-width: 768px) {
  table > thead > tr > th,
  table > tbody > tr > th,
  table > tfoot > tr > th,
  table > thead > tr > td,
  table > tbody > tr > td,
  table > tfoot > tr > td {
    padding: 15px;
  }

  table {
    border-radius: 10px;
  }

  .table--style1 > thead > tr > th {
    font-size: 16px;
  }

  .table--style1 > thead > tr > th,
  .table--style1 > tbody > tr > th,
  .table--style1 > tfoot > tr > th,
  .table--style1 > thead > tr > td,
  .table--style1 > tbody > tr > td,
  .table--style1 > tfoot > tr > td {
    font-size: 14px;
  }

  .table--style2 > thead > tr > th,
  .table--style2 > tbody > tr > th,
  .table--style2 > tfoot > tr > th,
  .table--style2 > thead > tr > td,
  .table--style2 > tbody > tr > td,
  .table--style2 > tfoot > tr > td {
    padding: 5px 10px;
  }

  .table--style3 > tbody > tr > th,
  .table--style3 > tfoot > tr > th,
  .table--style3 > thead > tr > td,
  .table--style3 > tbody > tr > td,
  .table--style3 > tfoot > tr > td {
    padding: 5px 10px;
  }

  .table--style3 > tbody > tr > td:first-child {
    font-size: 12px;
  }

  .table--style3 > tbody > tr > td {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  table > thead > tr > th,
  table > tbody > tr > th,
  table > tfoot > tr > th,
  table > thead > tr > td,
  table > tbody > tr > td,
  table > tfoot > tr > td {
    padding: 5px;
  }

  table {
    font-size: 10px;
  }

  .table--style1 > thead > tr > th,
  .table--style1 > tbody > tr > th,
  .table--style1 > tfoot > tr > th,
  .table--style1 > thead > tr > td,
  .table--style1 > tbody > tr > td,
  .table--style1 > tfoot > tr > td {
    font-size: 9px;
  }
}