/*------------- #CATEGORY LIST --------------*/


.category-list  {
  text-align: center;

  .category-list-item {
	padding: 30px 15px;
	display: inline-block;
	transition: all .3s ease;
	cursor: pointer;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 14px;
	position: relative;

	&:after {
	  content: '';
	  display: block;
	  width: 100%;
	  height: 2px;
	  background-color: transparent;
	  position: absolute;
	  left: 0;
	  bottom: -2px;
	  transition: all .3s ease;
	}

	&:hover {
	  &:after {
		background-color: var(--primary-accent-color);
	  }
	}

	&.active {
	  color: var(--primary-accent-color);

	  &:after {
		background-color: var(--primary-accent-color);
	  }
	}
  }
}

.category-list-wrap {
  border-bottom: 2px solid #dfe6ec;
}

/*------------- Responsive Mode --------------*/

@media (max-width: 768px) {
  .category-list .category-list-item {
	padding: 15px 10px;
  }
}