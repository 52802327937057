/*------------- #BREADCRUMBS --------------*/


.breadcrumbs {
  padding-left: 0;
  margin-bottom: 0;

  .breadcrumbs-item {
	font-size: 14px;
	display: inline-flex;
	align-items: center;
	font-weight: 500;
	padding: 19px 0;

	.crumina-icon {
	  font-size: 12px;
	  color: var(--white-color);
	  margin: 0 12px;
	  opacity: .6;
	}

	a {
	  color: var(--white-color);
	  opacity: .6;

	  &:hover {
		opacity: 1;
	  }
	}

	&.active {
	  opacity: 1;
	  font-weight: 700;
	  padding-left: 30px;
	  margin-left: 30px;
	  position: relative;

	  &:after {
		content: '';
		display: block;
		height: 100%;
		width: 1500px;
		max-width: 100vw;
		position: absolute;
		top: 0;
		left: 0;
	  }

	  span {
		color: var(--white-color);
		z-index: 5;
	  }
	}
  }
}

.crumina-breadcrumbs {
  overflow: hidden;
}

.breadcrumbs--blue {
  background-color: #6419ff;

  .breadcrumbs .breadcrumbs-item.active::after {
	background-color: #5513dc;
  }
}

.breadcrumbs--red {
  background-color: #81525c;

  .breadcrumbs .breadcrumbs-item.active::after {
	background-color: #8a5963;
  }
}

.breadcrumbs--red-themes {
  background-color: var(--red-themes--darken);

  .breadcrumbs .breadcrumbs-item.active::after {
	background-color: var(--red-themes);
  }
}

.breadcrumbs--orange-themes {
  background-color: var(--orange-themes--darken);

  .breadcrumbs .breadcrumbs-item.active::after {
	background-color: var(--orange-themes);
  }
}

.breadcrumbs--yellow-themes {
  background-color: var(--yellow-themes--darken);

  .breadcrumbs .breadcrumbs-item.active::after {
	background-color: var(--yellow-themes);
  }
}

.breadcrumbs--blue-themes {
  background-color: #00b4ff;

  .breadcrumbs .breadcrumbs-item.active::after {
	background-color: var(--blue-themes);
  }
}

.breadcrumbs--lime-themes {
  background-color: #7ac710;

  .breadcrumbs .breadcrumbs-item.active::after {
	background-color: var(--lime-themes);
  }
}

.breadcrumbs--dark-themes {
  background-color: #1b1c1d;

  .breadcrumbs .breadcrumbs-item.active::after {
	background-color: var(--dark-themes);
  }
}


/*------------- Responsive Mode --------------*/


@media (max-width: 768px) {
  .breadcrumbs .breadcrumbs-item.active {
	display: flex;
	padding-left: 0;
	margin-left: 0;
  }

  .breadcrumbs .breadcrumbs-item.active::after {
	left: -15px;
  }

  .crumina-breadcrumbs > .container {
	max-width: unset;
  }

  .breadcrumbs .breadcrumbs-item {
	padding: 12px 0;
  }
}