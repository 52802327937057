/*------------- #KNOWLEDGE-BASE --------------*/


.presentation-block {
  padding: 30px;
  border-radius: 5px;
  background-color: #f1f6fb;
  margin: 30px 0;
}

.presentation-block--light-blue {
  background-color: #f1f6fb;
}

.documentation-rate {
  text-align: center;
  background-color: var(--grey-themes);
  padding: 30px;
  border-radius: 5px;
  margin: 40px 0;

  .title {
	margin-bottom: 30px;
  }
}

.knowledge-base-article {
  .post-additional-info {
    font-weight: 500;
    color: var(--light-grey);
  }

  .entry-footer {
    padding-top: 40px;
    margin-top: 40px;
    border-top: 2px solid #dfe6ec;

    .socials {
      margin: 0;
    }
  }
}

.base-documentation-navigation {
  position: sticky;
  top: 100px;
  height: 90vh;
  overflow: auto;
  -ms-overflow-style: none;  // IE 10+
  scrollbar-width: none;  // Firefox
}

.base-documentation-navigation::-webkit-scrollbar {
  display: none;  // Safari and Chrome
}

.documentation-navigation-item {
  .title {
    text-transform: uppercase;
    font-size: 14px;
  }
}

.documentation-list {
  padding-left: 0;
  list-style: none;

  li {
    margin-bottom: 30px;
    font-size: 14px;
  }
}


/*------------- Responsive Mode --------------*/

@media (max-width: 992px) {
  .base-documentation-navigation {
    position: relative;
    top: auto;
    height: auto;
  }
}