/*------------- #POPUP-SUBSCRIBE --------------*/


.popup-subscribe {
  text-align: center;
  padding-right: 0 !important;

  &.modal {
	z-index: 999999;
	padding-right: 0 !important;
  }

  .modal-content {
	border-radius: 5px;
	overflow: hidden;
	border: none;

	&:before {
	  content: '';
	  display: block;
	  width: 100%;
	  height: 6px;
	  background-repeat: repeat-x;
	  -webkit-background-size: contain;
	  background-size: contain;
	  position: absolute;
	  top: 0;
	  left: 0;
	  z-index: 99;
	}
  }

  .modal-header {
	padding: 0;
	border-bottom: none;
  }

  .modal-dialog {
	max-width: calc(100% - 20px);
	width: 570px;
  }

  .modal-body {
	padding: 60px 100px;
  }

  .close {
	transition: all .3s ease;
	float: none;
	font-size: 14px;
	font-weight: 500;
	color: var(--light-grey);
	text-shadow: none;
	opacity: 1;
	margin: 0 auto;
	outline: none;

	&:hover {
	  color: var(--primary-accent-color);
	}
  }
}

.popup-subscribe .modal-content:before {
  background-image: url("../img/theme-content/backgrounds/subscribe-gradient-bg.png");
}

/*================= Responsive Mode ============*/

@media (max-width: 768px) {
  .popup-subscribe .modal-body {
	padding: 20px 40px;
  }

  .tabs--subscribe .nav-tabs {
	flex-wrap: wrap;
  }
}