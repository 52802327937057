/*------------- #HIGHEST RATED --------------*/


.highest-rated-wrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

.highest-rated-item {
  width: 31%;
}

.highest-rated-item-count {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;

  &:after {
	content: '';
	display: block;
	width: 100%;
	height: 4px;
	border-radius: 5px;
	margin-top: 20px;
  }

  &.c-primary {
	&:after {
	  background-color: var(--primary-accent-color);
	}
  }

  &.c-blue {
	&:after {
	  background-color: var(--blue-themes);
	}
  }

  &.c-lime {
	&:after {
	  background-color: var(--lime-themes);
	}
  }
}

.highest-rated-item-title {
  font-weight: 700;
}