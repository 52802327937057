/*------------- #VARIABLES --------------*/


// We use these as default colors throughout

$primary-accent-color: #6419ff !default;

$white-color: #fff !default;
$border-grey-color: #cfd8df !default;
$link-color: #272a2c !default;
$heading-color: #272a2c !default;
$footer-link-color: #bac1c9 !default;
$footer-text-color: #80868d !default;

$h1-fontsize: 48px !default;
$h2-fontsize: 42px !default;
$h3-fontsize: 36px !default;
$h4-fontsize: 30px !default;
$h5-fontsize: 24px !default;
$h6-fontsize: 18px !default;
$h-font-weight: 700 !default;
$h-margin-bottom: 20px !default;


// We use these to control various global styles

$body-bg-color: #fff !default;
$body-font-size: 16px !default;
$body-font-color: #43484e !default;
$body-font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$body-font-weight: 400 !default;
$body-letter-spacing: -.001em !default;
$base-line-height: 1.39 !default;

$light-grey: #8d9cab !default;
$icon-color: #9095a0 !default;

$grey-light-themes: #d9e3eb !default;
$grey-themes: #ebf2f8 !default;
$red-themes: #ff0173 !default;
$dark-themes: #272a2c !default;
$blue-themes: #00c6ff !default;
$yellow-themes: #ffd200 !default;
$orange-themes: #ff8a0b !default;
$green-themes: #00a651 !default;
$lime-themes: #8ad524 !default;

//Darken themes colors

$primary-accent-color--darken: darken($primary-accent-color, 10%) !default;

$white-color--darken: darken($white-color, 10%) !default;

$grey-light-themes--darken: darken($grey-light-themes, 10%) !default;
$grey-themes--darken: darken($grey-themes, 10%) !default;
$red-themes--darken: darken($red-themes, 10%) !default;
$dark-themes--darken: darken($dark-themes, 10%) !default;
$blue-themes--darken: darken($blue-themes, 10%) !default;
$yellow-themes--darken: darken($yellow-themes, 10%) !default;
$orange-themes--darken: darken($orange-themes, 10%) !default;
$green-themes--darken: darken($green-themes, 10%) !default;
$lime-themes--darken: darken($lime-themes, 10%) !default;

$placeholder-color: #838890 !default;
$input-dark-bg: #2c2f31 !default;
$input-grey-bg: #ebf2f8 !default;

//socials colors

$c-rss: #f4b459 !default;
$c-mail: #996DD1 !default;
$c-pinterest: #cc2127 !default;
$c-googleplus: #dd4b39 !default;
$c-google: #dd4b39 !default;
$c-facebook: #2f5b9d !default;
$c-twitter: #38bff1 !default;
$c-amazone: #F69B06 !default;
$c-behance: #2D2D2D !default;
$c-bing: #FFA616 !default;
$c-creative-market: #8DA741 !default;
$c-deviantart: #1B1B1B !default;
$c-dribbble: #f74881 !default;
$c-dropbox: #0BA4E0 !default;
$c-envato: #6D9C3E !default;
$c-flickr: #26A9E1 !default;
$c-instagram: #E75351 !default;
$c-kickstarter: #14E06E !default;
$c-linkedin: #4A8F9E !default;
$c-medium: #00E56B !default;
$c-periscope: #3FA4C4 !default;
$c-quora: #F85F62 !default;
$c-reddit: #F05825 !default;
$c-shutterstock: #008EC0 !default;
$c-skype: #00AAF1 !default;
$c-snapchat: #FFED45 !default;
$c-soundcloud: #FF3300 !default;
$c-spotify: #1ED760 !default;
$c-trello: #0079BF !default;
$c-tumblr: #36465D !default;
$c-vimeo: #1AB7EA !default;
$c-vk: #6383A8 !default;
$c-whatsapp: #25D366 !default;
$c-wikipedia: #000000 !default;
$c-wordpress: #21759B !default;
$c-youtube: #CD201F !default;
$c-messanger: #3b84f6 !default;
$c-telegram: #3d87c4 !default;
$c-email: #fd4e26 !default;
