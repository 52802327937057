/*------------- #ARTICLES LIST --------------*/


.crumina-articles-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  li {
	display: flex;
	align-items: center;
	margin-bottom: 20px;

	&:last-child {
	  margin-bottom: 0;
	}
  }

  .icon-article {
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	fill: #dfe6ec;
	margin-right: 10px;
  }
}