/*------------- #W-SOCIALS --------------*/


.w-tags {
  .widget-title {
	margin-bottom: 40px;
  }
}

.tags-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  li {
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;

	&:last-child {
	  margin-right: 0;
	}
  }

  a {
	border-radius: 30px;
	line-height: 1;
	padding: 9px 23px;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 700;
	background-color: #f1f6fb;

	&:hover {
	  background-color: var(--primary-accent-color);
	  color: var(--white-color);
	}
  }
}


/*------------- Responsive Mode --------------*/


@media (max-width: 768px) {

}