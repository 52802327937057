/*------------- #Styles for preloader --------------*/


#hellopreloader {
  display: block;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  background: var(--primary-accent-color);
  opacity: 1;
  background-size: 41px;
}

.preloader {
  width: 50%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*================= Responsive Mode ============*/
