/**
 *	This element is created inside your target element
 *	It is used so that your own element will not need to be altered
 **/
.time_circles {
    position: relative;
    width: 100%;
    height: 100%;
}

/**
 *	This is all the elements used to house all text used
 * in time circles
 **/
.time_circles > div {
    position: absolute;
    text-align: center;
  	width: 25% !important;
    display: flex;
    flex-direction: column-reverse;
    top: 50% !important;
    transform: translateY(-50%) !important;

    &:after {
        content: ':';
        display: block;
        font-size: 20px;
        line-height: 1;
        color: var(--white-color);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 700;
    }

    &:last-child:after {
        display: none;
    }
}

/**
 *	Titles (Days, Hours, etc)
 **/
.time_circles > div > h4 {
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 1;
  	text-transform: uppercase;
    color: var(--white-color);
    font-weight: 700;
}

/**
 *	Time numbers, ie: 12
 **/
.time_circles > div > span {
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    display: block;
    width: 100%;
    text-align: center;
    line-height: 1;
    font-weight: 700;
    color: var(--white-color);
}

.crumina-countdown-number {
    min-height: 100px !important;
    width: 100% !important;
    max-width: 400px !important;
    margin: 0 auto;
    display: inline-flex;
    z-index: 10;
    position: relative;
}