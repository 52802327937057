/*------------- #TABLE OF CONTENTS --------------*/

/*------------------------------------------------------------------

1. Blocks / Styles for Blocks
	1.1 Accordion
	1.2 Articles List
	1.3 Buttons
	1.4 Category List
	1.5 Checkboxes
	1.6 Clients
	1.7 Comments
	1.8 Contacts
	1.9 Domain Filter Panel
	1.10 Domain List
	1.11 Dropzone
	1.12 Faqs Block
	1.13 Features
	1.14 Heading
	1.15 Highest Rated
	1.16 Info Boxes
	1.17 Knowledge Base
	1.18 Lists
	1.19 Maps
	1.20 Navigation
	1.21 Navigation Search Popup
	1.22 Our Video
	1.23 Pricing Tables
	1.24 Quotes
	1.25 Radio Buttons
	1.26 Skills
	1.27 Sliders
	1.28 Socials
	1.29 Subscribe Popup
	1.30 Switcher
	1.31 Tables
	1.32 Tabs
	1.33 Teammembers
	1.34 Testimonials
	1.35 Theme Item
	1.36 User Menu Popup
	1.37 Statistics

2. Layouts / Styles for layouts
	2.1 Footer
	2.2 Free SSL Section
	2.3 Header
	2.4 Status Pages

3. Plugins / Styles for plugins
	3.1 Navigation
	3.2 Leaflet
	3.3 Magnific Popup
	3.4 Select2
	3.5 Swiper
	3.6 TimeCircles

4. Theme Styles / Main Theme Styles
	4.1 Blogs
	4.2 Breadcrumbs
	4.3 Custom Variables
	4.4 Forms
	4.5 Global
	4.6 Helper
	4.7 Mixins
	4.8 Preloader
	4.9 Root
	4.10 Stunning Header
	4.11 Typography

5. Vendors / Bootstrap

6. Widgets / Styles for all Widgets
	6.1 Widget About
	6.2 Widget Info
	6.3 Widget Latest Posts
	6.4 Widget Newsletter
	6.5 Widget Popular Tags
	6.6 Widget Search
	6.7 Widget Socials
	6.8 Widget Tags
	6.9 Widget Links


-------------------------------------------------------------------*/