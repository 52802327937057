/*------------- #STYLES for checkboxes --------------*/

.checkboxes-wrap {

  .checkbox {
    display: inline-block;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.checkbox label {
  cursor: pointer;
  padding-left: 0;
  margin: 0;
  text-transform: none;
  letter-spacing: -0.001em;
  font-weight: 700;
  font-size: 14px;
  color: inherit;

  span {
	margin-left: 0;
  }
}
.checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}
.checkbox .checkbox-material {
  vertical-align: middle;
  position: relative;
  top: 1px;
  padding-right: 8px;
  display: inline-block;
}
.checkbox .checkbox-material:before {
  display: block;
  position: absolute;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  height: 20px;
  width: 20px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  top: 0;
  -webkit-transform: scale3d(2.3,2.3,1);
  -moz-transform: scale3d(2.3,2.3,1);
  -o-transform: scale3d(2.3,2.3,1);
  -ms-transform: scale3d(2.3,2.3,1);
  transform: scale3d(2.3,2.3,1);
}
.checkbox .checkbox-material .check {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid var(--primary-accent-color);
  overflow: hidden;
  z-index: 1;
  border-radius: 3px;
  background-color: transparent;
}
.checkbox .checkbox-material .check:before {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  display: block;
  margin-top: -4px;
  margin-left: 6px;
  width: 0;
  height: 0;
  box-shadow: 0 0 0 0 inset;
  -webkit-animation: checkbox-off 0.3s forwards;
  -moz-animation: checkbox-off 0.3s forwards;
  -o-animation: checkbox-off 0.3s forwards;
  -ms-animation: checkbox-off 0.3s forwards;
  animation: checkbox-off 0.3s forwards;
}
.checkbox input[type=checkbox]:focus + .checkbox-material .check:after {
  opacity: 0.2;
}
.checkbox input[type=checkbox]:checked + .checkbox-material .check {
  background: var(--white-color);
  border-color: var(--primary-accent-color);
}
.checkbox input[type=checkbox]:checked + .checkbox-material .check:before {
  color: var(--primary-accent-color);
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  -webkit-animation: checkbox-on 0.3s forwards;
  -moz-animation: checkbox-on 0.3s forwards;
  -o-animation: checkbox-on 0.3s forwards;
  -ms-animation: checkbox-on 0.3s forwards;
  animation: checkbox-on 0.3s forwards;
}
.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check {
  border-color: var(--input-dark-bg);
  background-color: var(--input-dark-bg);
}
.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check::before {
  color: #1f2223;
}
.checkbox.clicked input[type=checkbox]:checked + .checkbox-material:before {
  -webkit-animation: rippleOn 500ms;
  -moz-animation: rippleOn 500ms;
  -o-animation: rippleOn 500ms;
  -ms-animation: rippleOn 500ms;
  animation: rippleOn 500ms;
}
.checkbox.clicked input[type=checkbox]:checked + .checkbox-material .check:after {
  -webkit-animation: rippleOn 500ms forwards;
  -moz-animation: rippleOn 500ms forwards;
  -o-animation: rippleOn 500ms forwards;
  -ms-animation: rippleOn 500ms forwards;
  animation: rippleOn 500ms forwards;
}
.checkbox.clicked input[type=checkbox]:not(:checked) + .checkbox-material:before {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
.checkbox.clicked input[type=checkbox]:not(:checked) + .checkbox-material .check:after {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
.checkbox input[type=checkbox][disabled] ~ .checkbox-material .check {
  border-color: var(--input-dark-bg);
  background-color: #1f2223;
}
.checkbox input[type=checkbox][disabled] + .checkbox-material .check:after {
  background-color: rgba(0,0,0, 0.87);
  transform: rotate(-45deg);
}

@keyframes checkbox-on {
  0% {
	box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
	box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
	box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes rippleOn {
  0% {
	opacity: 0;
  }
  50% {
	opacity: 0.2;
  }
  100% {
	opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
	opacity: 0;
  }
  50% {
	opacity: 0.2;
  }
  100% {
	opacity: 0;
  }
}

// END STYLES for checkboxes

/*------------- CHECKBOX buttons Style2 --------------*/

.checkbox--style2 {
  &.checkbox .checkbox-material .check {
    border: 1px solid var(--primary-accent-color);
    background: var(--grey-themes);
  }

  &.checkbox input[type="checkbox"]:checked + .checkbox-material .check {
    background: var(--white-color);
  }

  &.checkbox input[type="checkbox"]:checked + .checkbox-material .check::before {
    color: var(--primary-accent-color);
  }

  &.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check {
    border-color: #bec8d5;
    background: #bec8d5;
  }

  &.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check::before {
    color: var(--grey-themes);
    border-color: #bec8d5;
  }

  &.checkbox input[type="checkbox"][disabled] ~ .checkbox-material .check {
    border-color: #bec8d5;
    background: transparent;
  }
}


/*------------- CHECKBOX buttons Style3 --------------*/

.checkbox--style3 {

  .dot {
    margin-right: -4px;
    height: 5px;
    width: 5px;
    min-height: 5px;
    min-width: 5px;
    background-color: var(--light-grey);
    display: inline-block;
    border-radius: 100%;
  }

  &.checkbox .checkbox-material .check {
    border: 1px solid #d2dbe3;
  }

  &.checkbox input[type="checkbox"]:checked + .checkbox-material .check {
    background: var(--white-color);
    border-color: var(--lime-themes);
  }

  &.checkbox input[type="checkbox"]:checked + .checkbox-material .check::before {
    color: var(--lime-themes);
  }

  &.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check {
    border-color: #bec8d5;
    background: #bec8d5;
  }

  &.checkbox input[type="checkbox"]:checked:disabled + .checkbox-material .check::before {
    color: var(--grey-themes);
    border-color: #bec8d5;
  }

  &.checkbox input[type="checkbox"][disabled] ~ .checkbox-material .check {
    border-color: #d2dbe3;
    background: var(--grey-themes);
  }

  &.checkbox label {
    font-weight: 900;
    font-size: 18px;
  }
}