/*------------- #GLOBAL STYLES --------------*/

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body{
  position: relative;
  height: 100%;
}

body{
  color: var(--body-font-color);
  font-family: var(--font-family-body);
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: var(--base-line-height);
  letter-spacing: var(--body-letter-spacing);
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

blockquote {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}
a {
  color: var(--link-color);
  transition: all 0.15s linear;
  text-decoration: none;

  &:hover {
    color: var(--primary-accent-color);
    text-decoration: none;
  }
}

p {

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

figure {
  margin: 0;
  max-width: 100%;
}

// Make select elements 100% width by default
select {
  width: 100%;
  border: 1px solid $body-font-color;
  appearance:none;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
}


// Make sure textarea takes on height automatically
textarea { height: auto; min-height: 50px; }

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: $body-font-color;
}

textarea:-moz-placeholder,
input:-moz-placeholder {
  color: $body-font-color;
}

textarea:-ms-input-placeholder,
input:-ms-input-placeholder{
  color: $body-font-color;
}

input, textarea {
  -moz-box-shadow: none;
  box-shadow: none;

  &:invalid {
    border: 1px solid #ff00004d;
  }

  &:-moz-submit-invalid {
    box-shadow: none;
  }

  &.wpcf7-form-control {
    width: 100%;
  }
}


/* Text meant only for screen readers. */

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}
