/*------------- #HEADING --------------*/

.crumina-heading {
  position: relative;
  z-index: 5;
  margin-bottom: 30px;

  & > *:last-child {
	margin-bottom: 0;
  }

  & > *:first-child {
	margin-top: 0;
  }

  &.heading--uppercase {
	.heading-title {
	  text-transform: uppercase;
	}
  }

  &.heading--h1 {
	.heading-text {
	  font-size: 1.125em;
	}
  }

  &.heading--h2 {
	.heading-text {
	  font-size: 1.125em;
	}
  }

  &.heading--h3 {
	.heading-text {
	  font-size: 1.125em;
	}
  }

  &.heading--h4 {
	.heading-text {
	  font-size: 1em;
	}
  }

  &.heading--h5 {
	.heading-text {
	  font-size: 1em;
	}
  }

  &.heading--h6 {
	.heading-text {
	  font-size: 1em;
	  font-weight: 400;
	}
  }
}

.heading-title {
  display: inline-block;
  position: relative;
}

.heading-text {
  line-height: 1.3;
  font-weight: 500;
}

.heading--uppercase {
  text-transform: uppercase;
}

/*================= Responsive Mode ============*/

@media (max-width: 980px) {
  .crumina-heading[class*="heading--h"] .heading-text {
	font-size: 18px;
  }
}

@media (max-width: 768px) {

  .crumina-heading {
	margin-bottom: 20px;
  }
}

@media (max-width: 570px) {
  .crumina-heading[class*="heading--h"] .heading-text {
	font-size: 14px;
  }
}