/*------------- #FAQS BLOCK --------------*/


.crumina-features-item {
  text-align: center;

  img {
	margin-bottom: 15px;
  }

  .features-item-title {
	margin-bottom: 0;
  }
}